import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../sections/header-section"
import AboutSection from "../sections/about-section"
import ServicesSection from "../sections/services-section"
import TestimonialsSection from "../sections/testimonials-section"
import PortfolioSection from "../sections/portfolio-section"

const IndexPage = () => (
  <Layout>
    <SEO title="Oleksii Fedorenko" />
    <HeaderSection></HeaderSection>
    <AboutSection></AboutSection>
    <ServicesSection></ServicesSection>
    <PortfolioSection></PortfolioSection>
    <TestimonialsSection></TestimonialsSection>
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p><Link to="/page-2/">Go to page 2</Link></p>
    <p><Link to="/using-typescript/">Go to "Using TypeScript"</Link></p> */}
  </Layout>
)

export default IndexPage
