import React from 'react';
import Lottie from 'react-lottie';
import * as shape from '../lottie/shape-1'

import IconCode from "../images/icons/code-solid.svg";

const ServicesSection = () => {
  const shapeOptions = {
    loop: true,
    autoplay: true,
    animationData: shape,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="services-section pt-8 md:pt-12 lg:pt-24 bg-slate-950 relative z-10" id="services">
      <div className="shape-annimation-1 absolute left-0 top-0 opacity-80">
        <Lottie
          options={shapeOptions}
          height={500}
          width={500}
        />
      </div>

      <div className="container relative z-10">
        <div className="mb-14 max-w-lg">
          <h2 className="font-architects text-4xl text-sky-600 mb-2 ">Services</h2>
          <div className="text-wrap prose md:text-lg text-gray-300">
            <p>I offer an array of web development services designed to fuel the growth of your business.</p>
          </div>
        </div>

        <div className="row-group pb-10 mb-10 border-b border-slate-800">
          <div className="font-bold text-xl text-emerald-500 mb-8">Wordpress</div>

          <div className="flex flex-wrap -mx-4 -mb-8">
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Custom Theme Devlopment</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Your website deserves a unique identity that reflects your brand essence. I craft bespoke WordPress themes tailored to your specific requirements, ensuring your online presence stands out from the crowd</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Headless Wordpress</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Embrace the future of web development with headless WordPress solutions. I specialize in decoupling the front-end and back-end, offering unmatched flexibility, scalability, and speed for your website or application</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">E-commerce solutions</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Transform your WordPress website into a dynamic online store with my e-commerce solutions. From seamless shopping cart integrations to secure payment gateways, I empower you to drive sales and deliver exceptional shopping experiences.</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Plugins Development</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Extend the functionality of your WordPress site with custom plugins designed to address your unique needs. Whether you require advanced features, integrations, or enhancements, I provide solutions that optimize your website's performance and user experience.</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Performance Optimization</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Say goodbye to slow-loading websites! I utilize industry-leading techniques to optimize your WordPress site for lightning-fast speed, ensuring superior performance and enhanced user satisfaction.</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Security Upgrades</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Protect your online presence with my comprehensive security upgrades. From conducting vulnerability assessments to implementing robust security measures, I prioritize the safeguarding of your website and sensitive data.</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row-group">
          <div className="font-bold text-xl text-red-400 mb-8">Other Services</div>

          <div className="flex flex-wrap -mx-4 -mb-8">
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Static Websites</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum et, a eveniet ad cumque nostrum minima</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">HTML Emails</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum et, a eveniet ad cumque nostrum minima</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Front-end / Back-end</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum et, a eveniet ad cumque nostrum minima</p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-8">
              <div className="col-group">
                <div className="flex space-x-2 items-center mb-3">
                  <div className="flex-none">
                    <IconCode />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-base text-gray-300 mb-0">Marketing Services</h3>
                  </div>
                </div>

                <p className="text-gray-300 text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum et, a eveniet ad cumque nostrum minima</p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="text-center mt-12">
          <a href="#hs-chat-open" className="btn btn--primary">Let's Chat!</a>
        </div>

       
      </div>
    </div>
  )
}

export default ServicesSection