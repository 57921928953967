import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
// import { Player } from '@lottiefiles/react-lottie-player';
// import Lottie from 'react-lottie';

import * as animationData from '../lottie/62474-developing';
import BackgroundImage1 from '../images/illustrations/shape-bg-1.svg';
import BackgroundImage2 from '../images/illustrations/shape-bg-2.svg';

const AboutSection = () => {  
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="about-section py-16 lg:py-24 bg-blue-950 relative z-20" id="about-me">
      <BackgroundImage1 className="absolute -bottom-px right-0 bg-img w-full h-auto" />

      <BackgroundImage2 className="absolute -bottom-72 right-0 bg-img z-10 bg-animation-1" />
      
      <div className="container relative z-10">
        <div className="flex flex-wrap items-center -mx-4 xl:-mx-8">
          <div className="w-full lg:w-2/5 px-4 xl:px-8 hidden md:block">
            <div className="img-group relative flex justify-center mb-8">
              {/* <Lottie
                options={animationOptions}
                height={400}
                width={400}
              /> */}
              <StaticImage
                className="opacity-80"
                src="../images/uploads/oleksii-fedorenko.png"
                alt="Oleksii Fedorenko"
                placeholder="blurred"
                loading="eager"
                width={460}
                height={508}
              />
            </div>
          </div>

          <div className="w-full lg:w-3/5 px-4 xl:px-8">
            <h2 className="font-architects text-4xl text-sky-500 mb-4">about me</h2>
            <div className="text-wrap prose text-gray-300">
              <p>I was born in Kharkiv, UA 🇺🇦 in 1992, I embarked on a career path in engineering after completing my studies at Kharkiv National University of Construction and Architecture. However, my passion for technology and the ever-evolving world of web development led me to make a bold decision - to switch professions and pursue my dream of becoming a web developer.</p>
              <p>Driven by curiosity and a thirst for knowledge, I immersed myself in the world of coding and quickly honed my skills in various technologies and frameworks. Through continuous learning and hands-on experience, I transformed my enthusiasm into a successful career as a web developer.</p>
              <p>Today, I combine the analytical mindset of an engineer with the creativity and problem-solving skills of a developer. This unique blend allows me to approach web development with a meticulous attention to detail, ensuring that every aspect of your project is carefully crafted to perfection.</p>
              <p>With my engineering background, I bring a structured and methodical approach to every task, ensuring that projects are executed efficiently and effectively. I thrive on challenges, always seeking innovative solutions to complex problems.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection