import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import React, { useState } from "react"

import IconPhone from "../images/icons/phone.svg";

// import AOS from 'aos';
// import classNames from 'classnames';
// import { Watch } from 'scrollmonitor-react';
// import 'aos/dist/aos.css'

function Header() {
  // const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="masthead py-4 fixed top-0 inset-x-0 z-50 bg-slate-950/30 backdrop-blur">
      <div className="container">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="flex-1 px-4">
            <Link
              to={`/`}
              href="#responsive-header"
              className="font-bold text-white text-lg"
            >
              Oleksii Fedorenko
            </Link>
          </div>

          <div className="flex-none px-4 text-sm">
            <nav>
              <ul className="list-none p-0 -mx-3 my-0 flex items-center">
                <li className="px-3 hidden lg:block">
                  <Link to={`/#about-me`} className="font-medium text-gray-200 hover:text-white">About</Link>
                </li>

                <li className="px-3 hidden lg:block">
                  <Link to={`/#services`} className="font-medium text-gray-200 hover:text-white">Services</Link>
                </li>

                <li className="px-3 hidden lg:block">
                  <Link to={`/#projects`} className="font-medium text-gray-200 hover:text-white">Projects</Link>
                </li>

                <li className="px-3 hidden lg:block">
                  <Link to={`/#testimonials`} className="font-medium text-gray-200 hover:text-white">Testimonials</Link>
                </li>

                <li className="px-6 ml-3 lg:border-l border-gray-100/50 hidden sm:block">
                  <a href="tel:+380508662728" className="font-medium text-gray-200 hover:text-white flex items-center">
                    <IconPhone className="mr-3" />
                    +380 50 866-27-28
                  </a>
                </li>

                <li className="px-3">
                  <a href="#hs-chat-open" className="btn btn--primary btn--sm">Let's Chat!</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      
      {/* <div className="flex items-center flex-shrink-0 mr-6 text-white">
        <span className="text-xl font-semibold tracking-tight">
          {siteTitle}
        </span>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className={`${
            isExpanded ? `is-active` : `hidden`
          } flex items-center px-3 py-2 text-white border border-white rounded hover:text-white hover:border-white`}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
      >
        <div className="text-sm lg:flex-grow">
          <Link
            to={`/`}
            href="#responsive-header"
            className="block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-white"
          >
            Home
          </Link>
          <Link
            to={`/page-2`}
            className="block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-white"
          >
            page 2
          </Link>
        </div>
        <div>
          <a
            href="https://github.com/kosvrouvas/gatsby-tailwindcss-starter"
            target="_blank"
            rel="noreferrer"
            className="inline-block px-4 py-2 mt-4 text-sm leading-none text-white border border-white rounded hover:border-transparent hover:text-black hover:bg-white lg:mt-0"
          >
            Download
          </a>
        </div>
      </div> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
