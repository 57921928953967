import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

import Lottie from 'react-lottie';
import * as animationData from '../lottie/bg4-loop-light';

const TestimonialsSection = () => {  
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="testimonials-section pb-16 lg:pb-24 bg-slate-950 relative z-20" id="testimonials">
      <div className="bg-animation absolute -bottom-px inset-x-0">
        <Lottie
          options={animationOptions}
          height={400}
          width={400}
        />
      </div>

      <div className="container relative z-10">
        <div className="mb-12">
          <h2 className="font-architects text-4xl text-sky-600 mb-4">Testimonials</h2>
          <div className="text-wrap prose text-base text-gray-300">
            <p>The are a lot of clients I worked around the world. <br /> In general I have 95% satisfaction rate.</p> 
            <p className="text-sm">*All the review can be provven on Upwork</p>
          </div>
        </div>

        <div className="flex flex-wrap -mx-4 -mb-8">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-purple-950 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/dogiz.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Alon Zlatkin</div>
                    <div className="text-gray-200">CEO at Dogiz</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Accountable, professional and supper collaborative project. We will continue to work with Alex in the future and are recommending to choose Alex for your next project!</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-cyan-950 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/xinix.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Jalal Miah</div>
                    <div className="text-gray-200">CEO at Xinix</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">I have used Alexey for over 7 years. We have had a superb bonding, he understands my product, he understands my concepts. Alexey my man, thank you so much.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-fuchsia-950 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/gg.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Hugo Melis</div>
                    <div className="text-gray-200">Founder of Glamorous Goat</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Very happy with the work Alexey did for us. Super hard and fast worker overall. A great addition to your team and he will do his absolute best to make you happy."</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-sky-950 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/ga.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Sam Grice</div>
                    <div className="text-gray-200">Founder at Guardian Angel Network</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Alexey is great to work with! I had a very tight deadline on a very sensitive subject. He got his job completed on time and within budget. More so, the finial product looks great. I will need front-end work again soon, and I will 100% reach out to Alexey.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-rose-950 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/lv.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Julian Phillips</div>
                    <div className="text-gray-200">Co-Founder & Marketing Manager at Love Volounteers</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Alexey is an excellent front-end developer. He also has a good eye for design, which means he can make small tweaks and changes on the fly. Looking forward to working with Alexey again on our next project.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-teal-950 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/joshua.jpeg"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Joshua Goldfein</div>
                    <div className="text-gray-200">Founder & CEO at Mercury Digital Agency</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">I have nothing but fantastic things to say about Alexey. His work was very well done, at the high quality in both presentation and code that I require. I very much look forward to working with Alexey on future projects."</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-orange-950 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/eatingeu.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Kenny Dun</div>
                    <div className="text-gray-200">Founder of Eating Europe</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Alexey was great to work over the past 6+ months. He built our new wordpress website quickly without errors and then helped our organization transition into using the new features he had built. Since the site went live, Alexey has been very responsive for basic support and the small incremental projects we’ve needed. We certainly recommend Alexey for any Wordpress projects!</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-lime-950 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/andrew.jpg"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Andrew Brunner</div>
                    <div className="text-gray-200">Sales Representative at Cloud Realty</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">If I could give a higher than 5 star rating for Alexey, then I would. He is the most professional, and competent developer that I have ever worked with, and my Team has completed in excess of 100+ websites. Extremely creative, and offers suggestions above and beyond what you contract to do. He is 1 in a million, and I would highly recommend to anyone, or any company looking to complete a web development project.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-indigo-950 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/janis.jpg"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Janis Brix</div>
                    <div className="text-gray-200">Digital Strategist at Wigwam Digital</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">Alex has been a fantastic asset to our team. Not only he's a great front end developer, but also always responsive, helpful, accurate and efficient. Work was always done on time and with very little oversight. We would love to work with him also in future when bigger projects come our way. Thanks for everything!</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-zinc-900 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/gallipro.png"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Nino Jolly </div>
                    <div className="text-gray-200">CEO at Gallipro</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm"> Alexey is amazing, he is committed and respects deadlines, even when they are very short. Great communication on Skype and always makes sure everything is good. I highly recommend him and will be working with him on many future projects if he is available.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-stone-800 rounded-lg p-6 sm:p-8 -rotate-2">
              <div className="rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/td.jpeg"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Sam van der Heijden</div>
                    <div className="text-gray-200">Lead Developer at The DISTRIKT</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">I have nothing but fantastic things to say about Alexey. His work was very well done, at the high quality in both presentation and code that I require. I very much look forward to working with Alexey on future projects.</div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="col-group bg-red-950 rounded-lg p-6 sm:p-8 rotate-2">
              <div className="-rotate-2">
                <div className="flex -mx-2 items-center mb-3">
                  <div className="flex-none px-2">
                    <StaticImage
                      className="rounded-full"
                      src="../images/testimonials/toggl.jpeg"
                      alt=""
                      placeholder="blurred"
                      width={42}
                      height={42}
                    />
                  </div>
                  <div className="flex-1 px-2 text-sm">
                    <div className="text-gray-200 font-bold">Aleksandar Djokic </div>
                    <div className="text-gray-200">Marketing Manager at Toggl</div>
                  </div>
                </div>

                <div className="text-gray-300 text-sm">First time hiring Oleksii and it went perfectly! Understood the job easily, made changes quickly and completed the whole project in no time. Will definitely hire him again!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSection