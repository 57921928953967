import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

import BackgroundImage1 from '../images/illustrations/shape-bg-3.svg';
import BackgroundImage2 from '../images/illustrations/shape-bg-4-1.svg';

const PortfolioSection = () => {
  

  return (
    <div className="portfolio-section py-20 lg:py-28 bg-blue-950 relative z-20" id="projects">
      <BackgroundImage1 className="absolute -top-px left-0 bg-img w-full h-auto" />
      <BackgroundImage2 className="absolute -bottom-px left-0 bg-img w-full h-auto" />

      <div className="container relative z-10">
        <div className="mb-10 lg:mb-16 text-center">
          <h2 className="font-architects text-4xl text-sky-600 mb-4">Portfolio</h2>
          <div className="text-wrap prose md:text-lg text-gray-300 mx-auto">
            <p>There are more than 100 projects in my portfolio over the past 8 years. <br /> You can see some of corporate websites below</p>
          </div>
        </div>
      </div>

      <div className="portfolio relative z-10 px-4">
        <div className="flex -mx-2">
          <div className="flex-1 px-2">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-1@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={433}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-2@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={357}
            />
          </div>

          <div className="flex-1 px-2 mb-8">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-4@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={219}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-3@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={573}
            />
          </div>

          <div className="flex-1 px-2 mb-8 hidden xl:block">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-6@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={319}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-5@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={473}
            />
          </div>

          <div className="flex-1 px-2 mb-8 hidden sm:block">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-7@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={548}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-8@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={244}
            />
          </div>

          <div className="flex-1 px-2 mb-8 hidden md:block">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-10@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={347}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-9@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={445}
            />
          </div>

          <div className="flex-1 px-2 mb-8 hidden lg:block">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-11@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={479}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-12@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={313}
            />
          </div>

          <div className="flex-1 px-2 mb-8 hidden lg:block">
            <StaticImage
              className="mb-4 max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-13@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={301}
            />

            <StaticImage
              className="max-w-full hover:scale-105 transition duration-500 border-4 border-blue-600/20 rounded"
              src="../images/portfolio/frame-14@2x.jpg"
              alt=""
              placeholder="blurred"
              width={385}
              height={491}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default PortfolioSection