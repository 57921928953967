import React from 'react';
// import { StaticImage } from "gatsby-plugin-image"
// import InlineSVG from 'react-inlinesvg';
import DogizLogo from "../images/uploads/dogiz.svg";
import XinixLogo from "../images/uploads/xinix.svg";
import TalkatooLogo from "../images/uploads/talkatoo.svg";
import GalaxyLogo from "../images/uploads/galaxy.svg";
import FiniataLogo from "../images/uploads/finiata.svg";

// import Shape1 from "../images/illustrations/shape-1.svg";
// import Shape2 from "../images/illustrations/shape-2.svg";

import Lottie from 'react-lottie';
// import { Player } from '@lottiefiles/react-lottie-player';

import * as backgrounAnimation from '../lottie/bg-1.json'
import { StaticImage } from "gatsby-plugin-image"

const HeaderSection = () => {
  const shapeOptions = {
    loop: true,
    autoplay: true,
    animationData: backgrounAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="header-wrap relative overflow-hidden">
      <div className="header-bg-animation absolute inset-0">
        <Lottie
          options={shapeOptions}
          height={300}
          width={300}
        />
      </div>

      <div className="header-wrap__inner flex flex-col relative z-30">
        <div className="header-section-1 flex flex-col pt-36 pb-28 justify-center relative z-10 flex-1">
          <div className="container flex-1 flex flex-col justify-center items-center text-center">
            <div className="max-w-5xl">
              <h1 className="h1 mb-2 text-gray-300">Hello, I'm Oleksii!</h1>
              <div className="h2 text-transparent bg-clip-text bg-gradient-to-r from-sky-500 to-white">I'm a Full-Stack Wordpress deverloper</div>
              <div className="text-lg xl:text-xl text-gray-400 mt-8">Software Development for me is not just a profession, it's a way of life!</div>
            </div>
          </div>
        </div>

        <div className="clients-section py-6 bg-slate-950 flex-none">
          <div className="container">
            <div className="font-architects text-xl sm:text-2xl md:text-3xl text-sky-500 mb-4 text-center font-bold">My Clients</div>

            <div className="max-w-5xl mx-auto">
              <div className="flex -mx-3 sm:-mx-4 justify-between items-center">
                <div className="lg:flex-none px-3 sm:px-4">
                  <a href="https://www.dogiz.com/" target="_blank" rel="nofollow noopener">
                    <DogizLogo className="opacity-60 hover:opacity-80 transition-all duration-300" />
                  </a>
                </div>
                <div className="lg:flex-none px-3 sm:px-4">
                  <a href="https://galaxyvets.com/" target="_blank" rel="nofollow noopener">
                    <GalaxyLogo className="opacity-60 hover:opacity-80 transition-all duration-300" />
                  </a>
                </div>
                <div className="lg:flex-none px-3 sm:px-4 hidden sm:block">
                  <a href="https://xinix.co.uk/" target="_blank" rel="nofollow noopener">
                    <XinixLogo className="opacity-60 hover:opacity-80 transition-all duration-300" />
                  </a>
                </div>
                <div className="lg:flex-none px-3 sm:px-4">
                  <a href="https://talkatoo.com/" target="_blank" rel="nofollow noopener">
                    <TalkatooLogo className="opacity-60 hover:opacity-80 transition-all duration-300" />
                  </a>
                </div>
                <div className="lg:flex-none px-3 sm:px-4 hidden sm:block">
                  <a href="https://www.finiata.com/" target="_blank" rel="nofollow noopener">
                    <FiniataLogo className="opacity-60 hover:opacity-80 transition-all duration-300" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection